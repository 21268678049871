const tags = [
    { text: "George Washington", id: "1789-1797", extra: "1789-1797"},
    { text: "John Adams", id: "1797-1801", extra: "1797-1801"},
    { text: "Thomas Jefferson", id: "1801-1809", extra: "1801-1809"},
    { text: "James Madison", id: "1809-1817", extra: "1809-1817"},
    { text: "James Monroe", id: "1817-1825", extra: "1817-1825"},
    { text: "John Quincy Adams", id: "1825-1829", extra: "1825-1829"},
    { text: "Andrew Jackson", id: "1829-1837", extra: "1829-1837"},
    { text: "Martin Van Buren", id: "1837-1841", extra: "1837-1841"},
    { text: "William Harrison", id: "1841-1841", extra: "1841-1841"},
    { text: "John Tyler", id: "1841-1845", extra: "1841-1845"},
    { text: "James Polk", id: "1845-1849", extra: "1845-1849"},
    { text: "Zachary Taylor", id: "1849-1850", extra: "1849-1850"},
    { text: "Millard Fillmore", id: "1850-1853", extra: "1850-1853"},
    { text: "Franklin Pierce", id: "1853-1857", extra: "1853-1857"},
    { text: "James Buchanan", id: "1857-1861", extra: "1857-1861"},
    { text: "Abraham Lincoln", id: "1861-1865", extra: "1861-1865"},
    { text: "Andrew Johnson", id: "1865-1869", extra: "1865-1869"},
    { text: "Ulisses Grant", id: "1869-1877", extra: "1869-1877"},
    { text: "Rutherford Hayes", id: "1877-1881", extra: "1877-1881"},
    { text: "James Garfield", id: "1881-1881", extra: "1881-1881"},
    { text: "Chester Arthur", id: "1881-1885", extra: "1881-1885"},
    { text: "Grover Cleveland", id: "1885-1889, 1893-1897", extra: "1885-1889, 1893-1897"},
    { text: "Benjamin Harrison", id: "1889-1893", extra: "1889-1893"},
    { text: "William McKinley", id: "1897-1901", extra: "1897-1901"},
    { text: "Theodore Roosevelt", id: "1901-1909", extra: "1901-1909"},
    { text: "William Taft", id: "1909-1913", extra: "1909-1913"},
    { text: "Woodrow Wilson", id: "1913-1921", extra: "1913-1921"},
    { text: "Warren Harding", id: "1921-1923", extra: "1921-1923"},
    { text: "Calvin Coolidge", id: "1923-1929", extra: "1923-1929"},
    { text: "Herbert Hoover", id: "1929-1933", extra: "1929-1933"},
    { text: "Franklin Roosevelt", id: "1933-1945", extra: "1933-1945"},
    { text: "Harry Truman", id: "1945-1953", extra: "1945-1953"},
    { text: "Dwight Eisenhower", id: "1953-1961", extra: "1953-1961"},
    { text: "John Kennedy", id: "1961-1963", extra: "1961-1963"},
    { text: "Lyndon Johnson", id: "1963-1969", extra: "1963-1969"},
    { text: "Richard Nixon", id: "1969-1974", extra: "1969-1974"},
    { text: "Gerald Ford", id: "1974-1977", extra: "1974-1977"},
    { text: "Jimmy Carter", id: "1977-1981", extra: "1977-1981"},
    { text: "Ronald Reagan", id: "1981-1989", extra: "1981-1989"},
    { text: "George Bush Sr.", id: "1989-1993", extra: "1989-1993"},
    { text: "Bill Clinton", id: "1993-2001", extra: "1993-2001"},
    { text: "George Bush Jr.", id: "2001-2009", extra: "2001-2009"},
    { text: "Barack Obama", id: "2009-...", extra: "2009-..."},
  ];
  export default tags;
  